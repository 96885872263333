import React, { useState, useEffect, useContext } from "react";
import { PageProps } from "gatsby";
import { PDFViewer } from "@react-pdf/renderer";
import { isArray, isEmpty } from "lodash";

import Layout from "../../../components/layout";
import { SubmissionState } from "../../../context/SubmissionContext";
import { AuthenticationState } from "../../../context/AuthenticationContext";
import getApiData from "../../../lib/getApiData";
import H1 from "../../../components/htmlElements/h1";
import MyDocument from "../../../helpers/pdf/pdfDocument";

export default function SummaryPdfPage(props: PageProps) {
  // Extract variables from props
  const { id: materialId } = props;

  const authenticationState = useContext(AuthenticationState) || {
    token: "",
    isAuthenticated: false,
  };

  const { token, isAuthenticated } = authenticationState;

  const submissionState = useContext(SubmissionState) || "";
  const [loaded, setLoaded] = useState<boolean>(false);
  const [materialTitle, setMaterialTitle] = useState<string>("");
  const [metadata, setMetadata] = useState<object>({});
  const [totalPages, setTotalPages] = useState<number>(1);

  /**
   * Fetch material details (metadata)
   */
  useEffect(() => {
    (async () => {
      const details = await getApiData({
        endpoint: `materials/${materialId}`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "X-Amz-Security-Token": isAuthenticated ? token : "",
        },
      });

      if (details && details.status === 200) {
        setMetadata(details.data);
      }
    })();
  }, [materialId]);

  /**
   * Once metadata is loaded, fetch the material title if available.
   */
  useEffect(() => {
    if (!isEmpty(metadata)) {
      // IRIS Title from `instrument`
      if (
        metadata &&
        metadata.materialMetadata &&
        metadata.materialMetadata.instrument &&
        isArray(metadata.materialMetadata.instrument.title)
      ) {
        if (
          typeof metadata.materialMetadata?.instrument?.title[0] === "object"
        ) {
          if (metadata.materialMetadata.instrument.title[0]["#text"]) {
            setMaterialTitle(
              metadata.materialMetadata.instrument.title[0]["#text"]
            );
          }
        } else {
          setMaterialTitle(metadata.materialMetadata?.instrument?.title[0]);
        }
      } else {
        // We don't have title in instrument. So load record details without it.
      }

      // OASIS Title from `summary`
      if (
        metadata &&
        metadata.materialMetadata &&
        metadata.materialMetadata.summary
      ) {
        if (typeof metadata.materialMetadata?.summary?.title[0] === "object") {
          if (metadata.materialMetadata.summary.title[0]["#text"]) {
            setMaterialTitle(
              metadata.materialMetadata.summary.title[0]["#text"]
            );
          }
        } else if (
          typeof metadata.materialMetadata?.summary?.title === "object"
        ) {
          setMaterialTitle(metadata.materialMetadata?.summary?.title[0]);
        } else {
          setMaterialTitle(metadata.materialMetadata?.summary?.title);
        }
      } else {
        // We don't have title in summary. So load record details without it.
      }

      // When all done, set loaded to true
      setLoaded(true);
    }
  }, [metadata]);

  /**
   * In order to make this work during the build we need to use this fix:
   * https://stackoverflow.com/questions/76007339/server-error-error-pdfdownloadlink-is-a-web-specific-api-youre-either-using-t
   */
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <Layout>
      <H1 innerContent={materialTitle} additionalClasses="mb-5" />

      {isClient && loaded ? (
        <PDFViewer width="100%" height="1000px" showToolbar>
          <MyDocument
            metadata={metadata.materialMetadata}
            setTotalPages={setTotalPages}
            materialTitle={materialTitle}
          />
        </PDFViewer>
      ) : null}
    </Layout>
  );
}
